import React from 'react';

class MainPage extends React.Component {
  render() {
    return (
      <b>hung2</b>
    )
  }
}

export default MainPage