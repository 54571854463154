import axios from "axios";
function uploadFile(server, file, name, email, pass, uploadProgress) {
  var formData = new FormData();
  formData.append("email", email);
  formData.append("password", pass);
  formData.append("file", file);
  formData.append("fileName", name);

  return axios
    .post(`${new URL(server).origin}/upload`, formData, {
      onUploadProgress: uploadProgress,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log("Error", error);
      throw new Error(error);
    });
}

function login(server, email, pass) {
  var formData = new FormData();
  formData.append("email", email);
  formData.append("password", pass);

  return axios
    .post(`${new URL(server).origin}/login`, formData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log("Error", error);
      throw new Error(error);
    });
}

export default {
  uploadFile,
  login,
};
