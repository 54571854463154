import axios from 'axios'

// https://cors-anywhere.herokuapp.com/
async function uploadToZippy(blob, name, zipHash, zipName, uploadProgress) {
  // blob.name = name
  var formData = new FormData();
  formData.append("name", name);
  formData.append("notprivate", true);
  formData.append("zipname", zipName);
  formData.append("ziphash", zipHash);
  formData.append("embPlayerValues", false);
  formData.append("file", blob, name);
  return axios.post('https://www74.zippyshare.com/upload', formData, {
    onUploadProgress: uploadProgress
  })
  .then(function (response) {
    return response
  })
  .catch(function (error) {
    console.log(error);
  });
}

export default uploadToZippy