import axios from 'axios'


function shortLink(link) {
  let computeLink = `https://shrinkearn.com/api?api=3d74f527b7d1ddd5579e77984d1dc46d474ac939&url=${link}`
  return axios.get(`${computeLink}`)
  .then(function (response) {
    return response
  })
  .catch(function (error) {
    console.log(error);
  });
}

export default shortLink