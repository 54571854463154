import React from "react";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import MainPage from "./pages/MainPage";
import TestPage from "./pages/TestPage";
import { Layout, Menu } from "antd";
import "./App.css";
const { Header, Content } = Layout;

function App() {
  return (
    <Router>
      <Layout>
        <Header className="header">
          <div className="logo" />
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={["1"]}
            style={{ lineHeight: "44px" }}
          >
            <Menu.Item key="1">
              <NavLink to="/" style={{ padding: 10 }}>
                Home
              </NavLink>
            </Menu.Item>
          </Menu>
        </Header>
        <Layout>
          <Layout style={{ padding: "0 24px 24px" }}>
            <Content
              style={{
                background: "#fff",
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              <Route path="/" exact component={MainPage} />
              <Route path="/test" exact component={TestPage} />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </Router>
  );
}

export default App;
