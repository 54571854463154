import axios from "axios";

function shortLink(link) {
  return axios.put(
    "https://api.shorte.st/v1/data/url",
    `urlToShorten=${link}`,
    {
      headers: {
        "public-api-token": "214ef8761ffb60ce13ac749a77543520"
      }
    }
  );
}

export default shortLink;
