import { gapi } from "./../gapi/index";
import React from "react";

// function uploadToGoogleDrive() {
//   // Client ID
//   // 560625059869-2uidkfspu5c6bkrntoi1e7u6uk82tv76.apps.googleusercontent.com
//   // Client Secret
//   // 9ozLmFYYx5MNcJR_c519GcoZ
//   // api key AIzaSyBY-Jo5xY_U2LcRHLyhMrgk0szGBHClMOE
//   // 549881584657-lkaqci61ikcbvs0ip0ihp2les55f31lo.apps.googleusercontent.com
//   // ODejBN008cghvF9bJsKEwqM5
//   // AIzaSyBY-Jo5xY_U2LcRHLyhMrgk0szGBHClMOE
// }
var CLIENT_ID =
  "560625059869-2uidkfspu5c6bkrntoi1e7u6uk82tv76.apps.googleusercontent.com";
var API_KEY = "AIzaSyDDEdfw38gtRu2rG-V-nL9CrSuI3sa8nWk";
// Array of API discovery doc URLs for APIs used by the quickstart
var DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"
];
// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
var SCOPES =
  "https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.appdata https://www.googleapis.com/auth/drive.metadata";
/**
 *  On load, called to load the auth2 library and API client library.
 */

/**
 *  Initializes the API client library and sets up sign-in state
 *  listeners.
 */

class AOTGoogleDrive extends React.Component {
  constructor(props) {
    super(props);
    this.initClient = this.initClient.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  componentDidMount() {
    if (gapi) {
      gapi.load("client:auth2", this.initClient);
    }
  }

  initClient() {
    let vm = this;
    gapi.client
      .init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES
      })
      .then(
        function() {
          console.log("Work");
          vm.props.emitValue(gapi);
        },
        function(error) {
          console.log(error);
        }
      );
  }
  signIn() {
    if (!gapi) return;
    if (!gapi.auth2.getAuthInstance().isSignedIn.get())
      gapi.auth2.getAuthInstance().signIn();
  }

  signOut() {
    if (!gapi) return;
    if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
      gapi.auth2.getAuthInstance().signOut();
      this.props.emitValue(gapi);
    }
  }

  render() {
    return null;
  }
}

export default AOTGoogleDrive;
