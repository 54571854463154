import React, { Component } from "react";
import { Button, Row, Col, Input, Select, notification } from "antd";
import PropTypes from "prop-types";
import axios from "axios";
import config from "./config";
import $ from "jquery";
import * as R from "ramda";
import GoogleSheet from "./googlesheet";
const { Option } = Select;
const AOT_PREFIX = "https://asiaon.top/wp-admin";
// const AOT_PREFIX = "http://localhost/wp-admin";
const COOKIE =
  "wordpress_86a9106ae65537651a8e456835b316ab=admin%7C1585409213%7CjIx2oLAzHO8Nxjqwq9cGb1OvMOQTPCshBWh8ZyGFxQm%7C0e2f554639427294e16e84e8270f37ad30522eecffc5ae5c5a98ff43a4850805; _ga=GA1.1.1668771353.1573363592; splash_i=false; wordpress_cf_adm_use_adm=1; mailchimp_landing_site=https%3A%2F%2Flocalhost%2Fwp-admin%2Fadmin.php%3Fpage%3Dw3tc_general; G_AUTHUSER_H=4; wordpress_logged_in_86a9106ae65537651a8e456835b316ab=admin%7C1585409213%7CjIx2oLAzHO8Nxjqwq9cGb1OvMOQTPCshBWh8ZyGFxQm%7C5fe0dd29d58860d9d9f963a6294bad2df000def527288c828dfa1831549b5ba3; PHPSESSID=dec956d1ad95ac6f535568c962de0f07; KEEP_LOGIN=bexosazmcr7i17%253A1570379588";
// enter %0A
export default class AddPostWP extends Component {
  constructor(props, context) {
    super(props, context);
    this.addImage = this.addImage.bind(this);
    this.getFolders = this.getFolders.bind(this);
    this.createModula = this.createModula.bind(this);
    this.addFolder = this.addFolder.bind(this);
    this.moveImageToFolder = this.moveImageToFolder.bind(this);
    this.runAll = this.runAll.bind(this);
    this.openNotification = this.openNotification.bind(this);
    this.googleSheetFlow = this.googleSheetFlow.bind(this);
    this.state = {
      imageAdded: null,
      modulaTitle: "",
      postID: null,
      _wpnonce: null,
      parrentFolder: 0,
      listFolder: [],
      isLoading: false,
      cookie: localStorage.getItem("cookie_aot") || COOKIE,
      webAOT: localStorage.getItem("webAOT") || AOT_PREFIX,
    };
    localStorage.setItem("webAOT", this.state.webAOT);
    localStorage.setItem("cookie_aot", this.state.cookie);
    this.getFolders();
  }
  openNotification(mess) {
    this.setState({ isLoading: false });
    notification.open({
      message: "Info",
      description: `${mess}`,
      duration: 0,
    });
  }
  addImage() {
    let vm = this;
    vm.setState({ isLoading: true });
    let listLink = this.props.listLinkImage;
    let formData = new FormData();
    formData.append("urls", listLink);
    formData.append("width", "");
    formData.append("height", "");
    formData.append("mime-type", "");
    formData.append("action", "add_external_media_without_import");
    return axios
      .post(`${config().SERVER}/${vm.state.webAOT}/admin-ajax.php`, formData, {
        onUploadProgress: (pro) => {
          // uploadProgress(pro);
        },
        headers: {
          Authorization: "Basic YWRtaW46cylMcEdtRCFYT1JZdUgzXiNM",
          "Access-Control-Allow-Origin": window.location.href,
          helloWorld: this.state.cookie,
        },
      })
      .then(function (response) {
        vm.setState({
          isLoading: false,
          imageAdded: R.path(["data", "data", "attachments"], response),
        });
        return response;
      })
      .catch(vm.openNotification);
  }

  moveImageToFolder() {
    this.setState({ isLoading: true });
    let form = new FormData();
    let vm = this;
    form.append("action", "filebird_save_multi_attachments");
    form.append("folder_id", vm.state.parrentFolder);
    vm.state.imageAdded.forEach((item) => {
      form.append("ids[]", item.id);
    });
    return axios
      .post(`${config().SERVER}/${vm.state.webAOT}/admin-ajax.php`, form, {
        headers: {
          helloWorld: vm.state.cookie,
          "Access-Control-Allow-Origin": window.location.href,
        },
      })
      .then(() => {
        vm.setState({ isLoading: false });
      })
      .catch(vm.openNotification);
  }

  createModula() {
    let vm = this;
    vm.setState({ isLoading: true });
    return axios
      .get(
        `${config().SERVER}/${
          vm.state.webAOT
        }/post-new.php?post_type=modula-gallery`,
        {
          headers: {
            helloWorld: vm.state.cookie,
            "Access-Control-Allow-Origin": window.location.href,
          },
        }
      )
      .then(async (res) => {
        let parser = new DOMParser();
        let htmlDoc = parser.parseFromString(res.data, "text/html");
        let formPost = htmlDoc.querySelectorAll("form[id=post]");
        let findModula = Array.from(
          htmlDoc.querySelectorAll("script")
        ).filter((item) => item.text.match(".*var modulaHelper.*"));
        let _wpnonce_mnodula = null;
        if (findModula.length > 0) {
          _wpnonce_mnodula = findModula[0].text.match(`"_wpnonce":"([^"]*)`)[1];
        } else {
          return;
        }
        if (formPost[0]) {
          let selectorJ = $(formPost[0]);
          let postID = selectorJ.find("#post_ID").first().val();
          let _wpnonce = selectorJ.find("#_wpnonce").first().val();
          vm.setState({
            postID,
            _wpnonce,
          });
          let metaboxordernonce = selectorJ
            .find("#meta-box-order-nonce")
            .first()
            .val();
          let closedpostboxesnonce = selectorJ
            .find("#closedpostboxesnonce")
            .first()
            .val();
          let samplepermalinknonce = selectorJ
            .find("#samplepermalinknonce")
            .first()
            .val();
          // add image to gallery
          let formAddImage = new FormData();
          formAddImage.append("_wpnonce", _wpnonce_mnodula);
          formAddImage.append("gallery", postID);
          formAddImage.append("action", "modula_save_images");
          formAddImage.append(
            "images",
            JSON.stringify(
              vm.state.imageAdded.map((item, index) => {
                return {
                  ...R.pick(
                    ["id", "title", "alt", "description", "type"],
                    item
                  ),
                  height: 2,
                  width: 2,
                  link: "",
                  halign: "center",
                  valign: "middle",
                  target: "",
                  src: "",
                  full: R.path(["sizes", "full", "url"], item),
                  thumbnail: R.path(["sizes", "full", "url"], item),
                  resize: false,
                  index,
                  orientation: "portrait",
                  "original-title": item.title,
                  caption: "",
                };
              })
            )
          );
          await axios
            .post(
              `${config().SERVER}/${vm.state.webAOT}/admin-ajax.php`,
              formAddImage,
              {
                onUploadProgress: (pro) => {
                  // uploadProgress(pro);
                },
                headers: {
                  Authorization: "Basic YWRtaW46cylMcEdtRCFYT1JZdUgzXiNM",
                  "Access-Control-Allow-Origin": window.location.href,
                  helloWorld: vm.state.cookie,
                  "content-type": `multipart/form-data; boundary=${formAddImage._boundary}`,
                },
              }
            )
            .catch(vm.openNotification);
          // publish modula
          let formPublish = new FormData();
          formPublish.append("_wpnonce", _wpnonce);
          formPublish.append(
            "_wp_http_referer",
            "/wp-admin/post-new.php?post_type=modula-gallery"
          );
          formPublish.append("action", "editpost");
          formPublish.append("originalaction", "editpost");
          formPublish.append("post_author", 1);
          formPublish.append("post_type", "modula-gallery");
          formPublish.append("original_post_status", "auto-draft");
          formPublish.append("auto_draft", "");
          formPublish.append("user_ID", 1);
          formPublish.append("post_ID", postID);
          formPublish.append("meta-box-order-nonce", metaboxordernonce);
          formPublish.append("closedpostboxesnonce", closedpostboxesnonce);
          formPublish.append("post_title", vm.state.modulaTitle);
          formPublish.append("samplepermalinknonce", samplepermalinknonce);
          formPublish.append("modula_sorting", "manual");
          formPublish.append("modula_hidden_post_status", "draft");
          formPublish.append("post_status", "draft");
          formPublish.append("hidden_post_visibility", "public");
          formPublish.append("visibility", "public");
          formPublish.append("mm", new Date().getMonth() + 1);
          formPublish.append("jj", new Date().getDay());
          formPublish.append("aa", new Date().getFullYear());
          formPublish.append("hh", new Date().getHours());
          formPublish.append("mn", new Date().getMinutes());
          formPublish.append("ss", new Date().getSeconds());
          formPublish.append("original_publish", "Publish");
          formPublish.append("publish", "Publish");
          formPublish.append("modula-settings[type]", "creative-gallery");
          formPublish.append("modula-settings[gutter]", "10");
          formPublish.append("modula-settings[width]", "100%");
          formPublish.append("modula-settings[height]", "4000px");
          formPublish.append("modula-settings[img_size]", "500");
          formPublish.append("modula-settings[margin]", "10");
          formPublish.append("modula-settings[randomFactor]", "50");
          formPublish.append("modula-settings[lightbox]", "fancybox");
          formPublish.append("modula-settings[show_navigation]", "1");
          formPublish.append("modula-settings[maxImagesCount]", "");
          formPublish.append("modula-settings[filters][]", "");
          formPublish.append("modula-settings[allFilterLabel]", "All");
          formPublish.append("modula-settings[filterStyle]", "default");
          formPublish.append("modula-settings[filterLinkColor]", "");
          formPublish.append("modula-settings[filterLinkHoverColor]", "");
          formPublish.append("modula-settings[defaultActiveFilter]", "All");
          formPublish.append("modula-settings[filterPositioning]", "top");
          formPublish.append(
            "modula-settings[collapsibleActionText]",
            "Filter by"
          );
          formPublish.append("modula-settings[titleColor]", "");
          formPublish.append("modula-settings[captionColor]", "#ffffff");
          formPublish.append("modula-settings[wp_field_title]", "none");
          formPublish.append("modula-settings[wp_field_caption]", "none");
          formPublish.append("modula-settings[hide_title]", 1);
          formPublish.append("modula-settings[hide_description]", 1);
          formPublish.append("modula-settings[titleFontSize]", "0");
          formPublish.append("modula-settings[captionFontSize]", "14");
          formPublish.append("modula-settings[socialIconColor]", "#ffffff");
          formPublish.append("modula-settings[loadedScale]", "100");
          formPublish.append("modula-settings[loadedRotate]", "0");
          formPublish.append("modula-settings[loadedHSlide]", "0");
          formPublish.append("modula-settings[loadedVSlide]", "0");
          formPublish.append("modula-settings[effect]", "pufrobo");
          formPublish.append("modula-settings[hoverColor]", "#ffffff");
          formPublish.append("modula-settings[hoverOpacity]", "50");
          formPublish.append("modula-settings[borderSize]", "0");
          formPublish.append("modula-settings[borderRadius]", "0");
          formPublish.append("modula-settings[borderColor]", "#ffffff");
          formPublish.append("modula-settings[shadowSize]", "0");
          formPublish.append("modula-settings[shadowColor]", "#ffffff");
          formPublish.append("modula-settings[lazy_load]", "1");
          formPublish.append("modula-settings[enable_optimization]", "default");
          formPublish.append(
            "modula-settings[thumbnail_optimization]",
            "default"
          );
          formPublish.append(
            "modula-settings[lightbox_optimization]",
            "default"
          );
          formPublish.append("modula-settings[tablet_columns]", "2");
          formPublish.append("modula-settings[mobile_columns]", "1");

          await axios
            .post(
              `${config().SERVER}/${vm.state.webAOT}/post.php`,
              formPublish,
              {
                headers: {
                  helloWorld: vm.state.cookie,
                  "Access-Control-Allow-Origin": window.location.href,
                },
              }
            )
            .then(() => {
              vm.setState({
                isLoading: false,
                modulaCode: `[modula id="${postID}"]`,
              });
            })
            .catch(vm.openNotification);
          vm.setState({ isLoading: false });
        }
      })
      .catch(vm.openNotification);
  }

  getFolders() {
    let form = new FormData();
    let vm = this;
    form.append("action", "filebird_ajax_treeview_folder");
    return axios
      .post(`${config().SERVER}/${vm.state.webAOT}/admin-ajax.php`, form, {
        headers: {
          helloWorld: vm.state.cookie,
          "Access-Control-Allow-Origin": window.location.href,
        },
      })
      .then((res) => {
        let parser = new DOMParser();
        let htmlDoc = parser.parseFromString(res.data, "text/html");
        let listFolders = htmlDoc.querySelectorAll(
          "div[id=njt-filebird-folderTree]"
        );
        vm.setState({
          listFolder: [
            {
              title: "Ngoài cùng",
              value: 0,
            },
            ...Array.from($(listFolders[0]).find("li")).map((item) => ({
              title: item.innerText,
              value: parseFloat(item.dataset.id),
            })),
          ],
        });
      });
  }

  addFolder() {
    let form = new FormData();
    let vm = this;
    vm.setState({ isLoading: true });
    form.append("action", "filebird_ajax_update_folder_list");
    form.append("new_name", this.state.modulaTitle);
    form.append("parent", this.state.parrentFolder);
    form.append("folder_type", "default");
    form.append("type", "new");
    return axios
      .post(`${config().SERVER}/${vm.state.webAOT}/admin-ajax.php`, form, {
        headers: {
          helloWorld: vm.state.cookie,
          "Access-Control-Allow-Origin": window.location.href,
        },
      })
      .then(async (res) => {
        await vm.getFolders();
        vm.setState({
          parrentFolder: res.data.data.term_id,
          isLoading: false,
        });
      })
      .catch(vm.openNotification);
  }

  async runAll() {
    await this.addFolder();
    await this.addImage();
    await this.moveImageToFolder();
    this.createModula();
  }
  googleSheetFlow() {
    return GoogleSheet.addRow(
      {
        postid: this.state.postID,
        megalink: this.props.megalink,
        googlelink: this.props.googlelink,
        anon: this.props.anon,
        bayfile: this.props.bayfile,
        racaty: this.props.racaty,
        zipname: this.props.zipNamePre,
      },
      this.props.megaServer
    ).then(() => {
      this.openNotification("Đã backup vào file google sheet");
    });
  }

  render() {
    return (
      <div>
        <h1>POST BÀI VIẾT LÊN AOT</h1>
        <Input
          onChange={(e) => {
            localStorage.setItem("webAOT", e.target.value);
            this.setState({
              webAOT: e.target.value,
            });
          }}
          value={this.state.webAOT}
          placeholder="Link web WebAOT"
        />
        <Input
          onChange={(e) => {
            localStorage.setItem("cookie_aot", e.target.value);
            this.setState({
              cookie: e.target.value,
            });
          }}
          value={this.state.cookie}
          placeholder="Cookie AOT"
        />
        <Row>
          <Col span={12}>
            <Input
              onChange={(e) => {
                this.setState({
                  postID: e.target.value,
                });
              }}
              value={this.state.postID}
              placeholder="ID của bài viết sẽ post lên"
            />
          </Col>
          <Col span={12}>
            <Button
              loading={this.state.isLoading}
              onClick={this.googleSheetFlow}
              type="primary"
            >
              Lưu link download vào Google Sheets
            </Button>
          </Col>
        </Row>
        <Row align="middle" justify="center">
          <Col span={12}>
            Thư mục cha để tạo mới và là thư mục để thêm hình
            <Select
              placeholder="List folder"
              defaultValue="0"
              showSearch
              style={{ width: 400 }}
              value={this.state.parrentFolder}
              optionFilterProp="children"
              onChange={(value) =>
                this.setState({
                  parrentFolder: value,
                })
              }
              filterOption={(input, option) => {
                return (
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }}
            >
              {this.state.listFolder.map((item, i) => (
                <Option key={i} value={item.value} label={item.title}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={6}>
            <Input
              onChange={(e) =>
                this.setState({
                  modulaTitle: e.target.value,
                })
              }
              value={this.state.modulaTitle}
              placeholder="Tiêu đề modula, thư mục hình"
            />
          </Col>
          <Col span={6}>
            <Button
              loading={this.state.isLoading}
              onClick={this.addFolder}
              type="primary"
            >
              Tạo thư mục hình
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Button
              loading={this.state.isLoading}
              onClick={this.addImage}
              type="primary"
            >
              Thêm hình vào AOT
            </Button>
            <Button
              loading={this.state.isLoading}
              onClick={this.moveImageToFolder}
              type="primary"
            >
              Di chuyển ảnh vào thư mục
            </Button>
            <Button
              loading={this.state.isLoading}
              onClick={this.createModula}
              type="primary"
            >
              Tạo modula
            </Button>
            <Button
              loading={this.state.isLoading}
              onClick={this.runAll}
              type="primary"
            >
              Run all
            </Button>
          </Col>
          <Col span={12}>
            <Input
              onChange={(e) =>
                this.setState({
                  modulaCode: e.target.value,
                })
              }
              onClick={() => {
                navigator.clipboard.writeText(this.state.modulaCode);
                this.openNotification("Copied to clipboard");
              }}
              value={this.state.modulaCode}
              placeholder="Code modula"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

AddPostWP.propstype = {
  listLinkImage: PropTypes.string,
};
