import axios from "axios";

function addRow(data, server) {
  let {
    linkpost,
    postid,
    megalink,
    googlelink,
    anon,
    bayfile,
    racaty,
    shotly,
    tuiclick,
    posttitle,
    zipname
  } = data;
  return axios.post(`${server}/addrow`, {
    linkpost,
    postid,
    megalink,
    googlelink,
    anon,
    bayfile,
    racaty,
    shotly,
    tuiclick,
    posttitle,
    zipname
  });
}

export default {
  addRow
};
