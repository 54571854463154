import axios from "axios";
import config from "./config";
import * as R from "ramda";
async function uploadImage(
  imageBinary,
  name,
  { cookie, albumid, abs_position, bloggerbatchid, authUserBlogger }
) {
  const getUploadId = await axios.post(
    `${
      config().SERVER
    }/https://www.blogger.com/upload/blogger/photos/resumable?authuser=${authUserBlogger}`,
    {
      protocolVersion: "0.8",
      createSessionRequest: {
        fields: [
          {
            external: {
              name: "file",
              filename: name,
              put: {},
              size: imageBinary.size,
            },
          },
          {
            inlined: {
              name: "use_upload_size_pref",
              content: "true",
              contentType: "text/plain",
            },
          },
          {
            inlined: {
              name: "streamid",
              content: "blogger",
              contentType: "text/plain",
            },
          },
          {
            inlined: {
              name: "disable_asbe_notification",
              content: "true",
              contentType: "text/plain",
            },
          },
          {
            inlined: {
              name: "silo_id",
              content: "3",
              contentType: "text/plain",
            },
          },
          {
            inlined: {
              name: "title",
              content: name,
              contentType: "text/plain",
            },
          },
          {
            inlined: {
              name: "addtime",
              content: `${new Date().getTime()}`,
              contentType: "text/plain",
            },
          },
          {
            inlined: {
              name: "c189022504",
              content: "true",
              contentType: "text/plain",
            },
          },
          {
            inlined: {
              name: "batchid",
              content: bloggerbatchid,
              contentType: "text/plain",
            },
          },
          {
            inlined: {
              name: "album_id",
              content: albumid,
              contentType: "text/plain",
            },
          },
          {
            inlined: {
              name: "album_abs_position",
              content: abs_position,
              contentType: "text/plain",
            },
          },
          {
            inlined: {
              name: "client",
              content: "blogger",
              contentType: "text/plain",
            },
          },
        ],
      },
    },
    {
      // withCredentials: true,
      headers: {
        helloWorld: cookie,
      },
    }
  );
  let state = R.path(["data", "sessionStatus", "state"], getUploadId);
  if (state === "OPEN") {
    let upload_id = R.path(["data", "sessionStatus", "upload_id"], getUploadId);
    return axios
      .post(
        `${
          config().SERVER
        }/https://www.blogger.com/upload/blogger/photos/resumable?authuser=0&upload_id=${upload_id}`,
        imageBinary,
        {
          headers: {
            helloWorld: cookie,
          },
        }
      )
      .then((res2) => {
        let url = R.path(
          [
            "data",
            "sessionStatus",
            "additionalInfo",
            "uploader_service.GoogleRupioAdditionalInfo",
            "completionInfo",
            "customerSpecificInfo",
            "url",
          ],
          res2
        );
        if (typeof url === "string") {
          let splited = url.split("/");
          splited[2] = `${Math.ceil(Math.random() * 4)}.bp.blogspot.com`;
          return R.insert(splited.length - 1, "s9999", splited).join("/");
        }
        return url;
      });
  }
  return null;
}

export default {
  uploadImage,
};
