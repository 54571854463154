import React from 'react';
import MainForm from './../../../components/common/MainForm.js'

class MainPage extends React.Component {
  render() {
    return (
      <MainForm/>
    )
  }
}

export default MainPage