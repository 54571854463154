import axios from "axios";
import config from "./config";
function adlinkfly_base64_encode(e) {
  return btoa(
    encodeURIComponent(e).replace(/%([0-9A-F]{2})/g, function(e, n) {
      return String.fromCharCode("0x" + n);
    })
  );
}

function shortLink(link) {
  let computeLink =
    "https://tui.click/full/?api=" +
    encodeURIComponent("0c74e28ad33247c853938d89e5095c42bb49a8fc") +
    "&url=" +
    adlinkfly_base64_encode(link) +
    "&type=2";
  return axios
    .get(`${config().SERVER}/${computeLink}`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
    });
}

export default shortLink;
