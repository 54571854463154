import { Upload, Icon, Modal, Button } from "antd";
import React from "react";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class MyUpload extends React.Component {
  constructor(props) {
    super(props);
    this.removeAll = this.removeAll.bind(this);
  }

  state = {
    previewVisible: false,
    previewImage: "",
    fileList: [],
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChange = ({ fileList }) => {
    fileList = fileList.filter(
      (item) => !item.name.match(/(.*4u.*)|(.*\.com.*)/)
    );
    this.setState({ fileList });
    this.props.emitValue("fileList", fileList);
  };

  removeAll = ({ fileList }) => {
    this.setState({ fileList: [] });
    this.props.emitValue("fileList", fileList);
  };

  render() {
    const { previewVisible, previewImage, fileList } = this.state;

    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const props = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };
    return (
      <div className="clearfix">
        <Upload
          {...props}
          listType="text"
          fileList={fileList}
          // onPreview={this.handlePreview}
          onChange={this.handleChange}
          multiple
          className={"upload-list-inline"}
          accept="image/*,.zip,.rar"
        >
          {uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
        <Button type="primary" onClick={this.removeAll}>
          Remove all
        </Button>
      </div>
    );
  }
}
export default MyUpload;
