import axios from "axios";
import Cookies from "universal-cookie";
import config from "./config";

const COOKIES = new Cookies();
// https://cors-anywhere.herokuapp.com/
function createAlbum(albumName, cookies) {
  let formData = new FormData();
  formData.append("action", "create-album");
  formData.append("type", "album");
  formData.append("album[name]", albumName);
  formData.append("album[privacy]", "private_but_link");
  formData.append("album[new]", true);
  COOKIES.set("PHPSESSID", cookies.id, { path: "/" });
  COOKIES.set("KEEP_LOGIN", cookies.login, { path: "/" });
  return axios
    .post(`${config().SERVER}/https://asiaontop.imgbb.com/json`, formData, {
      withCredentials: true,
      headers: {
        Accept: "/",
        "Cache-Control": "no-cache"
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log("Error", error);
      throw new Error(error);
    });
}

async function uploadImageToAlbum(albumId, image, cookies) {
  var formData = new FormData();
  formData.append("source", image, image.name);
  formData.append("type", "file");
  formData.append("action", "upload");
  formData.append("timestamp", new Date().getTime());
  formData.append("album_id", albumId);
  COOKIES.set("PHPSESSID", cookies.id, { path: "/" });
  COOKIES.set("KEEP_LOGIN", cookies.login, { path: "/" });
  return axios
    .post(`${config().SERVER}/https://ibb.co/json`, formData, {
      withCredentials: true
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log("Error", error);
    });
}

async function uploadViaAPI(image) {
  var formData = new FormData();
  formData.append("key", "cfab0e8e27fe5b085b2dbfe3da7d10cc");
  formData.append("image", image, image.name);
  formData.append("name", image.name);
  return axios
    .post("https://api.imgbb.com/1/upload", formData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log("Error", error);
      throw new Error(error);
    });
}

export default {
  uploadImageToAlbum,
  createAlbum,
  uploadViaAPI
};
