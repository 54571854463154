import axios from "axios";
import config from "./config";
const CLIENT_ID = "8ac73808f09afa3";
// const CLIENT_SEC = 'db8a23a3dc0bc3dee9806d0bcb3ce47f9a6c53e5'
// https://cors-anywhere.herokuapp.com/
function authSimple() {
  window.open(
    `https://api.imgur.com/oauth2/authorize?client_id=${CLIENT_ID}&response_type=token`
  );
}

function auth() {}
function createAlbum(albumName, token) {
  let formData = new FormData();
  formData.append("title", albumName);
  formData.append("privacy", "hidden");
  return axios
    .post(`https://api.imgur.com/3/album`, formData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
    });
}
// function uploadImage(imageBinary, name, album, token) {
//   let formData = new FormData();
//   formData.append("Filedata", imageBinary);
//   formData.append("album", album);
//   formData.append("resize", -1);
//   formData.append("new_album_id", 0);
//   // formData.append('title', name)
//   return axios
//     .post(`${config().SERVER}/https://imgur.com/upload`, formData, {
//       headers: {
//         Authorization: `Bearer ${token}`
//       }
//     })
//     .then(function(response) {
//       return response;
//     })
//     .catch(function(error) {
//       console.log(error);
//     });
// }
function uploadImage(imageBinary, name, album, token) {
  let formData = new FormData();
  formData.append("files[]", imageBinary);
  formData.append("type", "upload");
  formData.append("watermark", 0);
  formData.append("watermark_position", "br");
  formData.append("watermark_logo", 1);
  formData.append("resize", 0);
  formData.append("server", "imgur");
  // formData.append('title', name)
  return (
    axios
      // .post(`${config().SERVER}/https://upanh.1doi1.com/upload.php`, formData)
      .post(`${config().SERVER}/http://upanh.vforum.vn/upload.php`, formData)
      .then(function(response) {
        console.log(response);
        return response;
      })
      .catch(function(error) {
        console.log(error);
      })
  );
}

export default {
  authSimple,
  auth,
  createAlbum,
  uploadImage
};
