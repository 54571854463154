import React from "react";
import {
  Button,
  Input,
  notification,
  Row,
  Checkbox,
  Card,
  Col,
  Select,
  Progress,
  Collapse,
} from "antd";
import MyUpload from "./MyUpload";
import AOTGoogleDrive from "./GoogleDrive";
import UploadToZippy from "./Zippyshare";
import Compressor from "compressorjs";
import ImgBB from "./ImgBB";
import Imgur from "./Imgur";
import Annofile from "./Anonfile";
import Bayfile from "./bayfiles";
import TuiClick from "./TuiClick";
import ShotLy from "./shotlyio";
import Mega from "./Mega";
import Racaty from "./Racaty";
import "operative/dist/operative.min.js";
import JSZip from "jszip";
import _ from "lodash";
import Shrink from "./Shrink";
import BlogSpot from "./blogspot";
import axios from "axios";
import * as R from "ramda";
import AddPost from "./add-post";
import shorte from "./shorte.st";

const { TextArea } = Input;
const { Panel } = Collapse;

const LOGO = require("./../../assets/aot.png");

var Minizip = require("./minizip.js");

// Init google drive

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const openNotification = (mess) => {
  notification.open({
    message: "Info",
    description: mess,
    duration: 0,
  });
};

class MainForm extends React.Component {
  constructor(props) {
    super(props);
    localStorage.getItem("serverBackend")
      ? localStorage.getItem("serverBackend")
      : localStorage.setItem("serverBackend", "http://localhost:9999");
    this.handleData = this.handleData.bind(this);
    this.zipFile = this.zipFile.bind(this);
    this.submit = this.submit.bind(this);
    this.waterMark = this.waterMark.bind(this);
    this.waterMarkUpload = this.waterMarkUpload.bind(this);
    this.imgBBFlow = this.imgBBFlow.bind(this);
    this.imgBBFlowViaAPI = this.imgBBFlowViaAPI.bind(this);
    this.imgUrFlow = this.imgUrFlow.bind(this);
    this.uploadToGoogleDrive = this.uploadToGoogleDrive.bind(this);
    this.tuiClickFlow = this.tuiClickFlow.bind(this);
    this.shotlyflow = this.shotlyflow.bind(this);
    this.shorteflow = this.shorteflow.bind(this);
    this.uploadToCloud = this.uploadToCloud.bind(this);
    this.shrinkFlow = this.shrinkFlow.bind(this);
    this.bloggerFlow = this.bloggerFlow.bind(this);
    this.genDownload = this.genDownload.bind(this);
    this.megaLogin = this.megaLogin.bind(this);
    this.getDriveInfo = this.getDriveInfo.bind(this);
  }
  state = {
    fileList: [],
    fileListWaterMark: [],
    passwordZip: "asiaon.top",
    zipNamePre: "",
    zipNamePost: ".asiaon.top",
    loading: false,
    imageLoading: false,
    progress: 0,
    imgbbToken: "",
    imgbbAlbumName: "",
    imgurToken: localStorage.getItem("imgurToken"),
    imgurAlbum: "",
    listLinkImgBB: localStorage.getItem("listLinkImgBB"),
    listLinkImageUploadRaw: [],
    phpSessId: localStorage.getItem("phpSessId"),
    keepLogin: localStorage.getItem("keepLogin"),
    sess_id: localStorage.getItem("sess_id"),
    zipHash: localStorage.getItem("zipHash"),
    zipName: localStorage.getItem("zipName"),
    test: null,
    codeHTMLShortLink: "",
    codeHTMLShortLinkShrink: "",
    checkBoxModel: {
      uploadGoogleDrive: true,
      uploadZippyshare: false,
      uploadAnno: false,
      uploadRacaty: false,
      uploadBayfile: false,
      uploadMega: true,
    },
    gapi: null,
    pathUploadGoogleDrive:
      localStorage.getItem("pathUploadGoogleDrive") || "root",
    driveTeamID: localStorage.getItem("driveTeamID"),
    zippyshareLink: localStorage.getItem("zippyshareLink"),
    annofileLink: localStorage.getItem("annofileLink"),
    bayfileLink: localStorage.getItem("bayfileLink"),
    racatyLink: localStorage.getItem("racatyLink"),
    megaLink: localStorage.getItem("megaLink"),
    megaProgress: 0,
    megaEmail: localStorage.getItem("megaEmail"),
    megaPass: localStorage.getItem("megaPass"),
    zippyProgress: 0,
    annoProgress: 0,
    racatyProgress: 0,
    bayfileProgress: 0,
    googleProgress: 0,
    googleLink: localStorage.getItem("googleLink"),
    googleTuiLink: null,
    imgQuality: 0.9,
    serverBackend:
      localStorage.getItem("serverBackend") || "http://localhost:9999",
    serverBackendMega:
      localStorage.getItem("serverBackendMega") || "http://localhost:4000",
    bloggerCookie:
      localStorage.getItem("bloggerCookie") ||
      "SID=twe6htAYJ5rsKc9TtcQsxHBItLUk9GT3fyzdICA6X1_GI35BXtdMwj68W2ofKvzEiCujaA.; __Secure-3PSID=twe6htAYJ5rsKc9TtcQsxHBItLUk9GT3fyzdICA6X1_GI35B8IkQVRcEWwEe5u7rY2YhsQ.; HSID=ABrSMa-5TlKYwoVBD; SSID=Am9xxuReuGxUP1ye0; APISID=vR2biAH5kIYcbQzu/AHcDoPpugUmK88hLd; SAPISID=A_96H2M8a8XCOWOv/AsegvIV8BwP1uh0UE; __Secure-HSID=ABrSMa-5TlKYwoVBD; __Secure-SSID=Am9xxuReuGxUP1ye0; __Secure-APISID=vR2biAH5kIYcbQzu/AHcDoPpugUmK88hLd; __Secure-3PAPISID=A_96H2M8a8XCOWOv/AsegvIV8BwP1uh0UE; NID=199=MGr3wEgZeKXsVBKQhMfqc33HI0Vq8wQ1JBjYdH2yAFG5xJ6vMuqr4-HszSbOPhZZVPCPN4_T3-fujOOpHowz3dKKjlGhV1w6YlIgJB1K1uttZIjuXCG1ny1opFGml893v5K4j4hpcFsgTN6LZ5c63rH6d4Gqqn5aMUGsCr2FWpaG6Gn44Qw-eAtzolmQoJyJ6xTnEZG3atD209EQHF5PFxSnkkfXI8XCYkBpYyziucyd-nRYjdDIF27EVsIVnIF-5wN1ZcXpCwTG4GSvosPkY1U3f_iTAthySN3fY14l2xB6nqrr60SlH6b-QsuG4L9wZmX6fCUc_OOpWIFn; _ga=GA1.2.102131006.1583396793; _gid=GA1.2.1545334878.1583396793; TL=-",
    bloggerAlbumid:
      localStorage.getItem("bloggerAlbumid") || "6809663907841844241",
    bloggeralbum_abs_position:
      localStorage.getItem("bloggeralbum_abs_position") || "57",
    bloggerbatchid: localStorage.getItem("bloggerbatchid") || "1585537724574",
    authUserBlogger: localStorage.getItem("authUserBlogger") || "0",
    googleInfo: {},
  };

  componentWillUpdate(nextProps, nextState) {}

  componentDidUpdate(prevProps, prevState, snapshot) {}

  getTokenImgUr() {
    Imgur.authSimple();
  }

  uploadToGoogleDrive(file, name) {
    return new Promise((resolve, reject) => {
      try {
        let metadata = {
          name: name, // Filename at Google Drive
          mimeType: "application/zip", // mimeType at Google Drive
          supportsTeamDrives: "true",
          parents: [this.state.pathUploadGoogleDrive], // Folder ID at Google Drive
          teamDriveId:
            !_.isEmpty(this.state.driveTeamID) &&
            !_.isNil(this.state.driveTeamID)
              ? this.state.driveTeamID
              : undefined,
        };
        let accessToken = this.state.gapi.auth.getToken().access_token;
        let form = new FormData();
        form.append(
          "metadata",
          new Blob([JSON.stringify(metadata)], {
            type: "application/json",
          })
        );
        form.append("file", file);
        axios
          .post(
            "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id&supportsTeamDrives=true",
            form,
            {
              onUploadProgress: (progressEvent) => {
                this.setState({
                  googleProgress: Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  ),
                });
              },
              headers: {
                Authorization: "Bearer " + accessToken,
              },
            }
          )
          .then((res) => {
            let fileId = R.path(["data", "id"], res);
            console.log(res);
            this.state.gapi.client.drive.permissions
              .create({
                fileId,
                resource: {
                  role: "reader",
                  type: "anyone",
                },
                fields: "id",
                supportsAllDrives: true,
              })
              .then(() => {
                this.state.gapi.client.drive.files
                  .get({
                    fileId,
                    fields: "webViewLink",
                    supportsAllDrives: true,
                  })
                  .then((response) => {
                    localStorage.setItem(
                      "googleLink",
                      response.result.webViewLink
                    );
                    this.setState({ googleLink: response.result.webViewLink });
                    resolve(true);
                    openNotification("Đã upload lên Google Drive");
                  });
              });
          });
      } catch (error) {
        openNotification(`Google Error ${error}`);
        reject(error);
      }
    });
  }

  getDriveInfo() {
    let googleInfo = {
      account: "",
      usage: 0,
      freeSpace: 0,
    };
    let vm = this;
    if (
      _.get(vm.state, ["gapi", "auth", "getToken"]) &&
      vm.state.gapi.auth.getToken()
    ) {
      let accessToken = vm.state.gapi.auth.getToken().access_token;
      return new Promise((res) => {
        axios
          .get("https://www.googleapis.com/drive/v3/about?fields=*", {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          })
          .then((driveRes) => {
            googleInfo.usage =
              parseFloat(_.get(driveRes, ["data", "storageQuota", "usage"])) /
              Math.pow(1024, 3);
            let free =
              _.get(driveRes, ["data", "storageQuota", "limit"]) -
              _.get(driveRes, ["data", "storageQuota", "usage"]);
            googleInfo.freeSpace = (free / Math.pow(1024, 2)).toFixed(2);
            console.log(driveRes);
            googleInfo.usage = googleInfo.usage.toFixed(2);
            googleInfo.account = _.get(driveRes, [
              "data",
              "user",
              "emailAddress",
            ]);
            vm.setState({
              googleInfo,
            });
          });
      });
    }
  }

  async bloggerFlow() {
    this.setState({
      imageLoading: true,
      progress: 0,
    });
    let listFile =
      this.state.fileListWaterMark.length > 0
        ? this.state.fileListWaterMark
        : this.state.fileList.map((item) => item.originFileObj);
    listFile = listFile.sort((a, b) => b.name.localeCompare(a.name));
    let listPromise = [];
    for (let key in listFile) {
      listPromise.push(
        BlogSpot.uploadImage(listFile[key], listFile[key].name, {
          cookie: this.state.bloggerCookie,
          albumid: this.state.bloggerAlbumid,
          abs_position: this.state.bloggeralbum_abs_position,
          bloggerbatchid: this.state.bloggerbatchid,
          authUserBlogger: this.state.authUserBlogger,
        })
          .then((res) => {
            this.setState({
              progress: this.state.progress + 1,
            });
            if (res) return res;
          })
          .catch((err) => {
            openNotification(`${err}`);
            return null;
          })
      );
    }
    let listLinkUploaded = await Promise.all(listPromise);
    this.setState(
      {
        listLinkImgBB: listLinkUploaded.join("\n"),
        listLinkImageUploadRaw: listLinkUploaded,
        imageLoading: false,
      },
      () => {
        localStorage.setItem("listLinkImgBB", this.state.listLinkImgBB);
      }
    );
  }
  async imgUrFlow() {
    this.setState({
      imageLoading: true,
      progress: 0,
    });
    let listFile =
      this.state.fileListWaterMark.length > 0
        ? this.state.fileListWaterMark
        : this.state.fileList.map((item) => item.originFileObj);
    listFile = listFile.sort((a, b) => b.name.localeCompare(a.name));
    let listPromise = [];
    for (let key in listFile) {
      listPromise.push(
        Imgur.uploadImage(listFile[key], listFile[key].name).then((res) => {
          this.setState({
            progress: this.state.progress + 1,
          });
          if (res) return res.data.url;
        })
      );
    }
    let listLinkUploaded = await Promise.all(listPromise);
    this.setState(
      {
        listLinkImgBB: listLinkUploaded.join("\n"),
        listLinkImageUploadRaw: listLinkUploaded,
        imageLoading: false,
      },
      () => {
        localStorage.setItem("listLinkImgBB", this.state.listLinkImgBB);
      }
    );
  }
  async imgBBFlowViaAPI() {
    this.setState({
      imageLoading: true,
    });
    let listLink = [];
    let listFile =
      this.state.fileListWaterMark.length > 0
        ? this.state.fileListWaterMark
        : this.state.fileList.map((item) => item.originFileObj);
    listFile = listFile.sort((a, b) => b.name.localeCompare(a.name));
    this.setState({
      progress: 0,
    });
    // upload
    let listPromise = [];
    for (let key = 0; key < listFile.length; key += 1) {
      try {
        if (listFile[key]) {
          listPromise.push(
            ImgBB.uploadViaAPI(listFile[key])
              .then((res) => {
                this.setState({
                  progress: this.state.progress + 1,
                });
                return res.data.data.image.url;
              })
              .catch((error) => {
                openNotification(
                  `Image ${listFile[key].name} have error ${error}`
                );
              })
          );
        }
      } catch (error) {
        openNotification(`Image ${listFile[key + 1].name} have error ${error}`);
      }
    }
    listLink = await Promise.all(listPromise);
    this.setState(
      {
        listLinkImgBB: listLink.join("\n"),
        listLinkImageUploadRaw: listLink,
      },
      () => {
        localStorage.setItem("listLinkImgBB", this.state.listLinkImgBB);
      }
    );
    this.setState({
      imageLoading: false,
    });
  }
  async imgBBFlow() {
    try {
      this.setState({
        imageLoading: true,
      });
      localStorage.setItem("phpSessId", this.state.phpSessId);
      localStorage.setItem("keepLogin", this.state.keepLogin);
      let cookies = {
        id: this.state.phpSessId,
        login: this.state.keepLogin,
      };
      let album = await ImgBB.createAlbum(this.state.zipNamePre, cookies);
      let listLink = [];
      let listFile =
        this.state.fileListWaterMark.length > 0
          ? this.state.fileListWaterMark
          : this.state.fileList.map((item) => item.originFileObj);
      listFile = listFile.sort((a, b) => b.name.localeCompare(a.name));
      this.setState({
        progress: 0,
      });
      for (let key = 0; key < listFile.length; key += 3) {
        let listPromise = [];
        if (listFile[key]) {
          listPromise.push(
            ImgBB.uploadImageToAlbum(
              album.data.album.id_encoded,
              listFile[key],
              cookies
            )
          );
        }
        if (listFile[key + 1]) {
          listPromise.push(
            ImgBB.uploadImageToAlbum(
              album.data.album.id_encoded,
              listFile[key + 1],
              cookies
            )
          );
        }
        if (listFile[key + 2]) {
          listPromise.push(
            ImgBB.uploadImageToAlbum(
              album.data.album.id_encoded,
              listFile[key + 2],
              cookies
            )
          );
        }
        if (listPromise.length === 0) {
          break;
        }
        await Promise.all(listPromise)
          .then((res) => {
            res.forEach((item) => {
              if (item) {
                listLink.push(item.data.image.url);
              }
              this.setState({
                progress: this.state.progress + 1,
              });
            });
          })
          .catch((error) => {
            openNotification(
              `Image ${listFile[key + 1].name} have error ${error}`
            );
          });
      }
      this.setState(
        {
          listLinkImgBB: listLink.join("\n"),
          listLinkImageUploadRaw: listLink,
        },
        () => {
          localStorage.setItem("listLinkImgBB", this.state.listLinkImgBB);
        }
      );
      this.setState({
        imageLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        imageLoading: false,
      });
      openNotification("Có lỗi khi upload lên imgbb" + error);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
      }
    });
  };

  handleData(fieldName, data) {
    let newData = {};
    newData[fieldName] = data;
    this.setState(newData);
    let zipFile = data && data.filter((item) => item.name.endsWith(".zip"));
    zipFile = zipFile || [];
    if (zipFile.length > 0) {
      this.setState({
        zipNamePre: zipFile[0].name,
      });
    }
  }

  async zipFile(listFile) {
    this.setState({ loading: true });
    const vm = this;
    vm.setState({ progress: 0 });
    let mz = new Minizip();
    let listPReadFile = [];
    for (let file of listFile) {
      listPReadFile.push(
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsArrayBuffer(file.originFileObj);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        })
      );
    }
    await Promise.all(listPReadFile)
      .then((res) => {
        res.map((item, index) => {
          vm.setState({ progress: vm.state.progress + 1 });
          mz.append(listFile[index].name, item, {
            password: vm.state.passwordZip,
            compressLevel: 0,
          });
          return true;
        });
      })
      .catch((error) => {
        console.log(error);
      });
    let name = `${this.state.zipNamePre}${this.state.zipNamePost}.zip`;
    let blob = new File([await mz.zip()], name, {
      type: "application/octet-binary",
    });
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name;
    a.click();
    await this.uploadToCloud(blob, name);
    this.setState({ loading: false });
  }
  async megaLogin() {
    this.setState({
      loading: true,
    });
    await Mega.login(
      this.state.serverBackendMega,
      this.state.megaEmail,
      this.state.megaPass
    ).then((res) => openNotification("Logged"));
    this.setState({
      loading: false,
    });
  }
  uploadToCloud(blob, name) {
    let vm = this;
    localStorage.setItem("sess_id", this.state.sess_id);
    localStorage.setItem("zipName", this.state.zipName);
    localStorage.setItem("zipHash", this.state.zipHash);
    localStorage.setItem("googledriveid", this.state.pathUploadGoogleDrive);
    // this.setState({
    //   zippyshareLink: null,
    //   racatyLink: null,
    //   bayfileLink: null,
    //   annofileLink: null,
    // });
    if (!blob) {
      openNotification("You not input any thing");
      return;
    }
    let p = [];
    if (this.state.checkBoxModel.uploadAnno)
      p.push(
        this.uploadToAnno(blob, name).catch((err) =>
          openNotification(`Anno Error ${err}`)
        )
      );
    if (this.state.checkBoxModel.uploadGoogleDrive)
      p.push(this.uploadToGoogleDrive(blob, name));
    if (this.state.checkBoxModel.uploadZippyshare) {
      p.push(
        UploadToZippy(
          blob,
          name,
          this.state.zipHash,
          this.state.zipName,
          (progressEvent) => {
            vm.setState({
              zippyProgress: Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              ),
            });
          }
        )
          .then((res) => {
            this.setState({
              zippyshareLink: this.extractContent(res.data),
            });
            localStorage.setItem("zippyshareLink", this.state.zippyshareLink);
          })
          .catch((err) => openNotification(`Zippy Error ${err}`))
      );
    }

    if (this.state.checkBoxModel.uploadRacaty) {
      p.push(
        Racaty.uploadFile(blob, name, this.state.sess_id, (progressEvent) => {
          vm.setState({
            racatyProgress: Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            ),
          });
        })
          .then((res) => {
            if (res.data[0]) {
              this.setState({
                racatyLink: `https://racaty.com/${res.data[0].file_code}`,
              });
              localStorage.setItem("racatyLink", this.state.racatyLink);
            }
          })
          .catch((err) => openNotification(`Racaty Error ${err}`))
      );
    }

    if (this.state.checkBoxModel.uploadBayfile) {
      p.push(
        Bayfile.uploadFile(blob, name, (progressEvent) => {
          vm.setState({
            bayfileProgress: Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            ),
          });
        })
          .then((res) => {
            this.setState({
              bayfileLink: res.data.data.file.url.full,
            });
            localStorage.setItem("bayfileLink", this.state.bayfileLink);
            openNotification(`Bayfile upload successfull`);
          })
          .catch((err) => openNotification(`Bayfile Error ${err}`))
      );
    }

    if (this.state.checkBoxModel.uploadMega) {
      p.push(
        Mega.uploadFile(
          vm.state.serverBackendMega,
          blob,
          name,
          vm.state.megaEmail,
          vm.state.megaPass,
          (progressEvent) => {
            vm.setState({
              megaProgress: Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              ),
            });
          }
        )
          .then((res) => {
            this.setState({
              megaLink: res.data.url,
            });
            localStorage.setItem("megaLink", this.state.megaLink);
            openNotification(`Mega upload successfull`);
          })
          .catch((err) => openNotification(`Mega ${err}`))
      );
    }

    return Promise.all(p);
  }

  uploadToAnno(file, name) {
    let vm = this;
    return Annofile.uploadFile(file, name, (progressEvent) => {
      vm.setState({
        annoProgress: Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        ),
      });
    }).then((res) => {
      this.setState({
        annofileLink: res.data.data.file.url.full,
      });
      openNotification(`Anno upload successfull`);
      localStorage.setItem("annofileLink", this.state.annofileLink);
    });
  }

  extractContent(s) {
    let span = document.createElement("span");
    span.innerHTML = s;
    let mustFind = null;
    let ele = span.getElementsByTagName("*");
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].id === "plain-links") {
        mustFind = ele[i];
        break;
      }
    }
    return mustFind.textContent || mustFind.innerText;
  }

  async submit(uploadZip) {
    if (uploadZip) {
      this.setState({
        loading: true,
      });
      let file = this.state.fileList.filter((item) => {
        let splitedName = item.name.split(".");
        return splitedName[splitedName.length - 1] === "zip";
      });
      if (file.length === 0) {
        openNotification("Không có file zip");
        return;
      }
      let name = file.length > 0 ? file[0].name : null;
      console.log(file);
      await this.uploadToCloud(file[0].originFileObj, name);
      this.setState({
        loading: false,
      });
    } else {
      this.zipFile(this.state.fileList);
    }
  }

  async waterMark() {
    this.setState({
      imageLoading: true,
    });
    let vm = this;
    let listFile = this.state.fileList.filter((item) => {
      let splitedName = item.name.split(".");
      return splitedName[splitedName.length - 1] !== "zip";
    });
    let results = [];
    let mz = new JSZip();
    for (let file of listFile) {
      let name = file.originFileObj.name;
      // eslint-disable-next-line
      await new Promise((res2, rej2) => {
        new Compressor(file.originFileObj, {
          quality: vm.state.imgQuality,
          maxHeight: 1440,
          maxWidth: 2560,
          async success(result) {
            await vm
              .watermarkImage(await getBase64(result))
              .then(async (file) => {
                new Compressor(file, {
                  quality: vm.state.imgQuality,
                  // maxHeight: 1440,
                  // maxWidth: 2560,
                  async success(result) {
                    result.name = name;
                    results.push(result);
                    mz.file(name, result, {
                      binary: true,
                    });
                    vm.setState({
                      progress: vm.state.progress + 1,
                    });
                    res2(true);
                  },
                });
              });
          },
          error(err) {
            console.log(err.message);
            rej2(err.mess);
          },
        });
      });
    }
    //  change file name
    results = results.map((item) => {
      item.name = `asiaon.top-${item.name}`;
      return item;
    });
    this.setState({
      fileListWaterMark: results,
    });
    let blob = await mz.generateAsync({
      type: "blob",
    });
    let name = `watermark.zip`;
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name;
    a.click();
    this.setState({
      imageLoading: false,
    });
  }

  async waterMarkUpload() {
    await this.waterMark();
    await this.imgBBFlow();
  }

  watermarkImage(elemImage64) {
    return new Promise((resolve) => {
      let testImage = new Image();
      testImage.onload = async function () {
        let h = testImage.height,
          w = testImage.width;
        const WATERMARK_IMAGE =
          "data:image/svg+xml;base64," +
          window.btoa(
            `<svg xmlns="http://www.w3.org/2000/svg" height="${h}" width="${w}">
          <foreignObject width="100%" height="100%">
            <div xmlns="http://www.w3.org/1999/xhtml">
              <div style="position: absolute;right: 0;bottom: 0;
                font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Verdana, sans-serif
                font-size: 14px;
                max-width: 30%;
                width: 30%;
                letter-spacing: 1px;
                background: transparent;
                color: #fff;
                padding: 0.25em .5em;
                border-radius: 0.25em;
                opacity: 0.6;
                margin: 0 0.125em 0.125em 0;">
                <img width="100%" height="100%" src="${LOGO}"/></div></div></foreignObject></svg>`
          );
        let waterMarkImage = new Image();
        waterMarkImage.onload = async () => {
          let canvas = Object.assign(document.createElement("canvas"), {
            width: w,
            height: h,
          });
          let ctx = canvas.getContext("2d");
          ctx.drawImage(testImage, 0, 0);
          ctx.drawImage(waterMarkImage, 0, 0);
          try {
            let url = await new Promise(
              (r) => canvas.toBlob((blob) => r(blob), "image/jpeg"),
              0.8
            );
            resolve(url);
          } catch (e) {
            console.error("Cannot watermark image with text:", {
              src: elemImage64,
              error: e,
            });
          }
        };
        waterMarkImage.src = WATERMARK_IMAGE;
      };
      testImage.src = elemImage64;
    });
  }

  genDownload() {
    let codeHTMLShortLink = `Download no watermark${
      this.state.zippyshareLink
        ? `
      
Zippyshare: <a href="${this.state.zippyshareLink}" target="_blank" rel="noopener">Download</a>`
        : ""
    }${
      this.state.megaLink
        ? `

Mega: <a href="${this.state.megaLink}" target="_blank" rel="noopener">Download</a>`
        : ""
    }${
      this.state.annofileLink
        ? `

Anonfile: <a href="${this.state.annofileLink}" target="_blank" rel="noopener">Download</a>`
        : ""
    }${
      this.state.bayfileLink
        ? `

Bayfiles (Fast): <a href="${this.state.bayfileLink}" target="_blank" rel="noopener">Download</a>`
        : ""
    }${
      this.state.racatyLink
        ? `

Racaty (Fast): <a href="${this.state.racatyLink}" target="_blank" rel="noopener">Download</a>`
        : ""
    }${
      this.state.googleLink
        ? `

Google Drive: <a href="${this.state.googleLink}" target="_blank" rel="noopener">Download</a>`
        : ""
    }

Password: asiaon.top
            `;
    this.setState({ codeHTMLShortLink });

    navigator.clipboard.writeText(codeHTMLShortLink);
    openNotification("Copied to clipboard");
  }

  async shorteflow() {
    this.setState({
      loading: true,
    });
    if (this.state.googleLink) {
      await shorte(this.state.googleLink).then((res) => {
        this.setState({
          googleTuiLink: _.get(res, ["data", "shortenedUrl"]),
        });
      });
    }
    if (this.state.zippyshareLink) {
      await shorte(this.state.zippyshareLink).then((res) => {
        this.setState({
          zippyTuiLink: _.get(res, ["data", "shortenedUrl"]),
        });
      });
    }
    if (this.state.annofileLink) {
      await shorte(this.state.annofileLink).then((res) => {
        this.setState({
          annoTuiLink: _.get(res, ["data", "shortenedUrl"]),
        });
      });
    }
    if (this.state.megaLink) {
      await shorte(this.state.megaLink).then((res) => {
        this.setState({
          megaTuiLink: _.get(res, ["data", "shortenedUrl"]),
        });
      });
    }
    if (this.state.bayfileLink) {
      await shorte(this.state.bayfileLink).then((res) => {
        this.setState({
          bayfileTuiLink: _.get(res, ["data", "shortenedUrl"]),
        });
      });
    }
    if (this.state.racatyLink) {
      await shorte(this.state.racatyLink).then((res) => {
        this.setState({
          racatyTuiLink: _.get(res, ["data", "shortenedUrl"]),
        });
      });
    }
    this.setState({
      codeHTMLShortLink: `Download no watermark${
        this.state.zippyTuiLink
          ? `
      
Zippyshare: <a href="${this.state.zippyTuiLink}" target="_blank" rel="noopener">Download</a>`
          : ""
      }${
        this.state.megaTuiLink
          ? `

Mega: <a href="${this.state.megaTuiLink}" target="_blank" rel="noopener">Download</a>`
          : ""
      }${
        this.state.annoTuiLink
          ? `

Anonfile: <a href="${this.state.annoTuiLink}" target="_blank" rel="noopener">Download</a>`
          : ""
      }${
        this.state.bayfileTuiLink
          ? `

Bayfiles (Fast): <a href="${this.state.bayfileTuiLink}" target="_blank" rel="noopener">Download</a>`
          : ""
      }${
        this.state.racatyTuiLink
          ? `

Racaty (Fast): <a href="${this.state.racatyTuiLink}" target="_blank" rel="noopener">Download</a>`
          : ""
      }${
        this.state.googleTuiLink
          ? `

Google Drive: <a href="${this.state.googleTuiLink}" target="_blank" rel="noopener">Download</a>`
          : ""
      }

Password: asiaon.top
            `,
    });
    this.setState({
      loading: false,
    });
  }
  async shotlyflow() {
    this.setState({
      loading: true,
    });
    if (this.state.googleLink) {
      await ShotLy(this.state.googleLink).then((res) => {
        this.setState({
          googleTuiLink: _.get(res, ["data", "shortenedUrl"]),
        });
      });
    }
    if (this.state.zippyshareLink) {
      await ShotLy(this.state.zippyshareLink).then((res) => {
        this.setState({
          zippyTuiLink: _.get(res, ["data", "shortenedUrl"]),
        });
      });
    }
    if (this.state.annofileLink) {
      await ShotLy(this.state.annofileLink).then((res) => {
        this.setState({
          annoTuiLink: _.get(res, ["data", "shortenedUrl"]),
        });
      });
    }
    if (this.state.megaLink) {
      await ShotLy(this.state.megaLink).then((res) => {
        this.setState({
          megaTuiLink: _.get(res, ["data", "shortenedUrl"]),
        });
      });
    }
    if (this.state.bayfileLink) {
      await ShotLy(this.state.bayfileLink).then((res) => {
        this.setState({
          bayfileTuiLink: _.get(res, ["data", "shortenedUrl"]),
        });
      });
    }
    if (this.state.racatyLink) {
      await ShotLy(this.state.racatyLink).then((res) => {
        this.setState({
          racatyTuiLink: _.get(res, ["data", "shortenedUrl"]),
        });
      });
    }
    this.setState({
      codeHTMLShortLink: `Download no watermark${
        this.state.zippyTuiLink
          ? `
      
Zippyshare: <a href="${this.state.zippyTuiLink}" target="_blank" rel="noopener">Download</a>`
          : ""
      }${
        this.state.megaTuiLink
          ? `

Mega: <a href="${this.state.megaTuiLink}" target="_blank" rel="noopener">Download</a>`
          : ""
      }${
        this.state.annoTuiLink
          ? `

Anonfile: <a href="${this.state.annoTuiLink}" target="_blank" rel="noopener">Download</a>`
          : ""
      }${
        this.state.bayfileTuiLink
          ? `

Bayfiles (Fast): <a href="${this.state.bayfileTuiLink}" target="_blank" rel="noopener">Download</a>`
          : ""
      }${
        this.state.racatyTuiLink
          ? `

Racaty (Fast): <a href="${this.state.racatyTuiLink}" target="_blank" rel="noopener">Download</a>`
          : ""
      }${
        this.state.googleTuiLink
          ? `

Google Drive: <a href="${this.state.googleTuiLink}" target="_blank" rel="noopener">Download</a>`
          : ""
      }

Password: asiaon.top
            `,
    });
    this.setState({
      loading: false,
    });
  }
  async tuiClickFlow() {
    this.setState({
      loading: true,
    });
    if (this.state.googleLink) {
      await TuiClick(this.state.googleLink).then((res) => {
        this.setState({
          googleTuiLink: _.get(res, ["headers", "x-final-url"]),
        });
      });
    }
    if (this.state.zippyshareLink) {
      await TuiClick(this.state.zippyshareLink).then((res) => {
        this.setState({
          zippyTuiLink: _.get(res, ["headers", "x-final-url"]),
        });
      });
    }
    if (this.state.annofileLink) {
      await TuiClick(this.state.annofileLink).then((res) => {
        this.setState({
          annoTuiLink: _.get(res, ["headers", "x-final-url"]),
        });
      });
    }
    if (this.state.megaLink) {
      await TuiClick(this.state.megaLink).then((res) => {
        this.setState({
          megaTuiLink: _.get(res, ["headers", "x-final-url"]),
        });
      });
    }
    if (this.state.bayfileLink) {
      await TuiClick(this.state.bayfileLink).then((res) => {
        this.setState({
          bayfileTuiLink: _.get(res, ["headers", "x-final-url"]),
        });
      });
    }
    if (this.state.racatyLink) {
      await TuiClick(this.state.racatyLink).then((res) => {
        this.setState({
          racatyTuiLink: _.get(res, ["headers", "x-final-url"]),
        });
      });
    }
    this.setState({
      codeHTMLShortLink: `Download no watermark${
        this.state.zippyTuiLink
          ? `
      
Zippyshare: <a href="${this.state.zippyTuiLink}" target="_blank" rel="noopener">Download</a>`
          : ""
      }${
        this.state.megaTuiLink
          ? `

Mega: <a href="${this.state.megaTuiLink}" target="_blank" rel="noopener">Download</a>`
          : ""
      }${
        this.state.annoTuiLink
          ? `

Anonfile: <a href="${this.state.annoTuiLink}" target="_blank" rel="noopener">Download</a>`
          : ""
      }${
        this.state.bayfileTuiLink
          ? `

Bayfiles (Fast): <a href="${this.state.bayfileTuiLink}" target="_blank" rel="noopener">Download</a>`
          : ""
      }${
        this.state.racatyTuiLink
          ? `

Racaty (Fast): <a href="${this.state.racatyTuiLink}" target="_blank" rel="noopener">Download</a>`
          : ""
      }
      ${
        this.state.googleTuiLink
          ? `

Google Drive: <a href="${this.state.googleTuiLink}" target="_blank" rel="noopener">Download</a>`
          : ""
      }

Password: asiaon.top
            `,
    });
    this.setState({
      loading: false,
    });
  }

  async shrinkFlow() {
    this.setState({
      loading: true,
    });
    if (this.state.zippyshareLink) {
      await Shrink(this.state.zippyshareLink).then((res) => {
        this.setState({
          zippyTuiLink: _.get(res, ["data", "shortenedUrl"]),
        });
      });
    }
    if (this.state.annofileLink) {
      await Shrink(this.state.annofileLink).then((res) => {
        this.setState({
          annoTuiLink: _.get(res, ["data", "shortenedUrl"]),
        });
      });
    }
    if (this.state.megaLink) {
      await Shrink(this.state.megaLink).then((res) => {
        this.setState({
          megaTuiLink: _.get(res, ["data", "shortenedUrl"]),
        });
      });
    }
    if (this.state.bayfileLink) {
      await Shrink(this.state.bayfileLink).then((res) => {
        this.setState({
          bayfileTuiLink: _.get(res, ["data", "shortenedUrl"]),
        });
      });
    }
    if (this.state.racatyLink) {
      await Shrink(this.state.racatyLink).then((res) => {
        this.setState({
          racatyTuiLink: _.get(res, ["data", "shortenedUrl"]),
        });
      });
    }
    this.setState({
      codeHTMLShortLinkShrink: `Download no watermark

${
  this.state.zippyTuiLink
    ? `Zippyshare: <a href="${this.state.zippyTuiLink}" target="_blank" rel="noopener">Download</a>`
    : ""
}

${
  this.state.megaTuiLink
    ? `Mega: <a href="${this.state.megaTuiLink}" target="_blank" rel="noopener">Download</a>`
    : ""
}

${
  this.state.annoTuiLink
    ? `Anonfile: <a href="${this.state.annoTuiLink}" target="_blank" rel="noopener">Download</a>`
    : ""
}

${
  this.state.bayfileTuiLink
    ? `Bayfiles (Fast): <a href="${this.state.bayfileTuiLink}" target="_blank" rel="noopener">Download</a>`
    : ""
}

${
  this.state.racatyTuiLink
    ? `Racaty (Fast): <a href="${this.state.racatyTuiLink}" target="_blank" rel="noopener">Download</a>`
    : ""
}
${
  this.state.googleTuiLink
    ? `Google Drive (Fast): <a href="${this.state.googleTuiLink}" target="_blank" rel="noopener">Download</a>`
    : ""
}

Password: asiaon.top
      `,
    });
    this.setState({
      loading: false,
    });
  }

  render() {
    const {
      passwordZip,
      zipNamePre,
      zipNamePost,
      zippyshareLink,
      progress,
      listLinkImgBB,
      // imgbbAlbumName,
      phpSessId,
      keepLogin,
      megaLink,
      megaTuiLink,
      racatyLink,
      racatyTuiLink,
      bayfileLink,
      bayfileTuiLink,
      codeHTMLShortLink,
    } = this.state;
    const { zippyTuiLink, annoTuiLink } = this.state;
    return (
      <div>
        <AOTGoogleDrive
          ref="googleDrive"
          emitValue={(gapi) =>
            this.setState(
              {
                gapi,
              },
              this.getDriveInfo
            )
          }
        />{" "}
        <MyUpload emitValue={this.handleData} />{" "}
        <Row>
          <Col span={8}>
            <Input
              addonBefore="Zip Name prefix"
              onChange={(e) =>
                this.setState({
                  zipNamePre: e.target.value,
                })
              }
              value={zipNamePre}
            />
          </Col>
          <Col span={8}>
            <Input
              onChange={(e) =>
                this.setState({
                  zipNamePost: e.target.value,
                })
              }
              value={zipNamePost}
              addonBefore="Zip Name postfix"
            />
          </Col>
          <Col span={8}>
            <Input
              onChange={(e) =>
                this.setState({
                  passwordZip: e.target.value,
                })
              }
              value={passwordZip}
              addonBefore="Zip password"
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Input
              onChange={(e) =>
                this.setState(
                  {
                    driveTeamID: e.target.value,
                  },
                  () => {
                    localStorage.setItem("driveTeamID", this.state.driveTeamID);
                  }
                )
              }
              value={this.state.driveTeamID}
              addonBefore="Google Drive Team ID"
            />
          </Col>
          <Col span={12}>
            <Input
              onChange={(e) =>
                this.setState(
                  {
                    pathUploadGoogleDrive: e.target.value,
                  },
                  () => {
                    localStorage.setItem(
                      "pathUploadGoogleDrive",
                      this.state.pathUploadGoogleDrive
                    );
                  }
                )
              }
              value={this.state.pathUploadGoogleDrive}
              addonBefore="Google Drive Folder ID"
            />
          </Col>
        </Row>
        <Input
          onChange={(e) =>
            this.setState({
              sess_id: e.target.value,
            })
          }
          value={this.state.sess_id}
          addonBefore="Racaty Session ID (sess_id)"
        />
        <Row>
          <Col span={12}>
            <Input
              onChange={(e) =>
                this.setState({
                  zipName: e.target.value,
                })
              }
              value={this.state.zipName}
              addonBefore="zipName"
            />
          </Col>
          <Col span={12}>
            <Input
              onChange={(e) =>
                this.setState({
                  zipHash: e.target.value,
                })
              }
              value={this.state.zipHash}
              addonBefore="Ziphash"
            />
          </Col>
        </Row>
        {Object.keys(this.state.checkBoxModel).map((value, index) => {
          return (
            <Checkbox
              key={index}
              checked={this.state.checkBoxModel[value]}
              onChange={(e) => {
                let newData = {};
                newData.checkBoxModel = this.state.checkBoxModel;
                newData.checkBoxModel[value] = e.target.checked;
                this.setState(newData);
              }}
            >
              {" "}
              {value}{" "}
            </Checkbox>
          );
        })}{" "}
        <Select
          style={{ width: "100%" }}
          onChange={(e) =>
            this.setState(
              {
                serverBackend: e,
              },
              localStorage.setItem("serverBackend", e)
            )
          }
          value={this.state.serverBackend}
        >
          <Select.Option value="http://localhost:9999">
            http://localhost:9999
          </Select.Option>
          <Select.Option value="https://aot-dev.herokuapp.com">
            https://aot-dev.herokuapp.com
          </Select.Option>
        </Select>
        <Row>
          <Col span={8}>
            <b>
              Email account Google: <i>{this.state.googleInfo.account}</i>
            </b>
          </Col>
          <Col span={8} style={{ color: "red" }}>
            <b>
              Usage: <i>{this.state.googleInfo.usage} GB</i>
            </b>
          </Col>
          <b style={{ color: "red" }}>
            Free: <i>{this.state.googleInfo.freeSpace} MB</i>
          </b>
        </Row>
        <Row>
          <Col span={12}>
            <Input
              onChange={(e) => {
                localStorage.setItem("megaEmail", e.target.value);
                this.setState({
                  megaEmail: e.target.value,
                });
              }}
              value={this.state.megaEmail}
              addonBefore="Mega Email"
            />
          </Col>
          <Col span={10}>
            <Input
              onChange={(e) => {
                localStorage.setItem("megaPass", e.target.value);
                this.setState({
                  megaPass: e.target.value,
                });
              }}
              value={this.state.megaPass}
              addonBefore="Mega Password"
            />
          </Col>
          <Col span={2}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={this.megaLogin}
              loading={this.state.loading}
            >
              Login Mega
            </Button>{" "}
          </Col>
        </Row>
        Server Upload Mega
        {/* <Select
          style={{ width: "100%" }}
          onChange={(e) =>
            this.setState(
              {
                serverBackendMega: e,
              },
              localStorage.setItem("serverBackendMega", e)
            )
          }
          value={this.state.serverBackendMega}
        >
          <Select.Option value="http://localhost:4000">
            http://localhost:4000
          </Select.Option>
          <Select.Option value="https://aot-mega.herokuapp.com">
            https://aot-mega.herokuapp.com
          </Select.Option>
        </Select> */}
        <Input
          onChange={(e) =>
            this.setState(
              {
                serverBackendMega: e.target.value,
              },
              localStorage.setItem("serverBackendMega", e.target.value)
            )
          }
          value={this.state.serverBackendMega}
          placeholder="Server Upload Mega"
        />
        {
          <Row
            style={{
              marginTop: "20px",
            }}
          >
            <Button
              key={`${this.state.gapi}-in`}
              type="primary"
              htmlType="submit"
              onClick={() => this.refs.googleDrive.signIn()}
              loading={this.state.loading}
            >
              Sign In Google{" "}
              {this.state.gapi &&
              this.state.gapi.auth2 &&
              this.state.gapi.auth2.getAuthInstance().isSignedIn.get()
                ? "(Signed)"
                : "(Not Signed)"}{" "}
            </Button>{" "}
            <Button
              key={`${this.state.gapi}-out`}
              type="primary"
              htmlType="submit"
              onClick={() => this.refs.googleDrive.signOut()}
              loading={this.state.loading}
            >
              Sign Out Google{" "}
              {this.state.gapi &&
              this.state.gapi.auth2 &&
              this.state.gapi.auth2.getAuthInstance().isSignedIn.get()
                ? "(Signed)"
                : "(Not Signed)"}{" "}
            </Button>{" "}
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => this.submit(false)}
              loading={this.state.loading}
            >
              Zip and upload{" "}
            </Button>{" "}
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => this.submit(true)}
              loading={this.state.loading}
            >
              Upload zip file{" "}
            </Button>{" "}
            <Button
              type="primary"
              htmlType="submit"
              onClick={async () => {
                await this.submit(true);
                this.genDownload();
                // this.shotlyflow();
                // this.tuiClickFlow();
              }}
              loading={this.state.loading}
            >
              Upload zip file and Shortlink{" "}
            </Button>{" "}
            <Button
              type="primary"
              htmlType="submit"
              onClick={this.genDownload}
              loading={this.state.loading}
            >
              Gen file link
            </Button>{" "}
          </Row>
        }{" "}
        <Row>
          Google:{" "}
          <Progress
            type="circle"
            percent={this.state.googleProgress}
            width={50}
          />
          Zippyprogress:{" "}
          <Progress
            type="circle"
            percent={this.state.zippyProgress}
            width={50}
          />
          Anno progress:{" "}
          <Progress
            type="circle"
            percent={this.state.annoProgress}
            width={50}
          />
          Racaty progress:{" "}
          <Progress
            type="circle"
            percent={this.state.racatyProgress}
            width={50}
          />
          Bayfile progress:{" "}
          <Progress
            type="circle"
            percent={this.state.bayfileProgress}
            width={50}
          />
          Mega progress:{" "}
          <Progress
            type="circle"
            percent={this.state.megaProgress}
            width={50}
          />
        </Row>
        <Collapse style={{ marginTop: 5, marginBottom: 5 }}>
          <Panel header="Link download" key="1">
            <Input
              onChange={(e) =>
                this.setState({
                  zippyshareLink: e.target.value,
                })
              }
              value={zippyshareLink}
              addonBefore="Zippyshare"
            />
            <Input
              onChange={(e) =>
                this.setState({
                  annofileLink: e.target.value,
                })
              }
              value={this.state.annofileLink}
              addonBefore="Annofile Link"
            />
            <Input
              onChange={(e) =>
                this.setState({
                  bayfileLink: e.target.value,
                })
              }
              value={bayfileLink}
              addonBefore="Bayfiles"
            />
            <Input
              onChange={(e) =>
                this.setState({
                  racatyLink: e.target.value,
                })
              }
              value={racatyLink}
              addonBefore="Racaty"
            />
            <Input
              onChange={(e) =>
                this.setState({
                  megaLink: e.target.value,
                })
              }
              value={megaLink}
              addonBefore="Mega Link"
            />
            <Input
              onChange={(e) =>
                this.setState({
                  googleLink: e.target.value,
                })
              }
              value={this.state.googleLink}
              addonBefore="Google Link"
            />
          </Panel>
        </Collapse>
        {
          /* Short Link */
          <Card
            title="Shortlink"
            style={{
              width: "100%",
              display: "none",
            }}
          >
            <Input
              onChange={(e) =>
                this.setState({
                  zippyTuiLink: e.target.value,
                })
              }
              value={zippyTuiLink}
              addonBefore="ShortLink Zippy"
            />
            <Input
              onChange={(e) =>
                this.setState({
                  annoTuiLink: e.target.value,
                })
              }
              value={annoTuiLink}
              addonBefore="ShortLink anno"
            />
            <Input
              onChange={(e) =>
                this.setState({
                  bayfileTuiLink: e.target.value,
                })
              }
              value={bayfileTuiLink}
              addonBefore="ShortLink Bayfile"
            />
            <Input
              onChange={(e) =>
                this.setState({
                  racatyTuiLink: e.target.value,
                })
              }
              value={racatyTuiLink}
              addonBefore="ShortLink Racity"
            />
            <Input
              onChange={(e) =>
                this.setState({
                  megaTuiLink: e.target.value,
                })
              }
              value={megaTuiLink}
              addonBefore="ShortLink Mega"
            />
            <Input
              onChange={(e) =>
                this.setState({
                  googleTuiLink: e.target.value,
                })
              }
              value={this.state.googleTuiLink}
              addonBefore="ShortLink Google"
            />
            <TextArea rows={7} value={codeHTMLShortLink} />
            <TextArea rows={7} value={this.state.codeHTMLShortLinkShrink} />
            <Button
              type="primary"
              htmlType="submit"
              onClick={this.shorteflow}
              loading={this.state.loading}
            >
              ShortLink shorte{" "}
            </Button>{" "}
            <Button
              type="primary"
              htmlType="submit"
              onClick={this.shotlyflow}
              loading={this.state.loading}
            >
              ShortLink Shotly{" "}
            </Button>{" "}
            <Button
              type="primary"
              htmlType="submit"
              onClick={this.tuiClickFlow}
              loading={this.state.loading}
            >
              ShortLink{" "}
            </Button>{" "}
            <Button
              type="primary"
              htmlType="submit"
              onClick={this.shrinkFlow}
              loading={this.state.loading}
            >
              ShortLink Shrink{" "}
            </Button>{" "}
          </Card>
        }{" "}
        <b> {progress} </b>{" "}
        <Button
          type="primary"
          htmlType="submit"
          onClick={this.waterMark}
          loading={this.state.imageLoading}
        >
          Add water mark{" "}
        </Button>{" "}
        {/* <Button
          type="primary"
          htmlType="submit"
          onClick={this.waterMarkUpload}
          loading={this.state.imageLoading}
        >
          Add water mark and upload{" "}
        </Button>{" "} */}
        <Button
          type="primary"
          htmlType="submit"
          onClick={async () => {
            await this.waterMark();
            this.imgBBFlowViaAPI();
          }}
          loading={this.state.imageLoading}
        >
          Add water mark and upload via API{" "}
        </Button>{" "}
        <Button
          type="primary"
          htmlType="submit"
          onClick={async () => {
            await this.waterMark();
            this.imgUrFlow();
          }}
          loading={this.state.imageLoading}
        >
          Add water mark and upload via IMGUR{" "}
        </Button>{" "}
        <Button
          type="primary"
          htmlType="submit"
          onClick={async () => {
            await this.waterMark();
            this.bloggerFlow();
          }}
          loading={this.state.imageLoading}
        >
          Add water mark and upload via Blogger{" "}
        </Button>{" "}
        <Button
          type="primary"
          onClick={async () => {
            await this.waterMark();
            await this.bloggerFlow();
            this.refs.addPost.runAll();
          }}
          style={{ backgroundColor: "green" }}
          loading={this.state.imageLoading}
        >
          Add water mark, upload via Blogger and create Modula{" "}
        </Button>
        <Row>
          <Col span={6}>
            <Input
              onChange={(e) =>
                this.setState({
                  imgQuality: e.target.value,
                })
              }
              value={this.state.imgQuality}
              addonBefore="Image quality"
            />
          </Col>
          <Col span={6}>
            <Input
              onChange={(e) =>
                this.setState({
                  phpSessId: e.target.value,
                })
              }
              value={phpSessId}
              addonBefore="PHPSESSID"
            />
          </Col>
          <Col span={6}>
            <Input
              onChange={(e) =>
                this.setState({
                  keepLogin: e.target.value,
                })
              }
              value={keepLogin}
              addonBefore="KEEPLOGIN"
            />
          </Col>
          <Col span={6}>
            <Input
              onChange={(e) =>
                this.setState({
                  zipNamePre: e.target.value,
                })
              }
              value={zipNamePre}
              addonBefore="Imgbb Album name"
            />
          </Col>
          <TextArea
            rows={4}
            value={listLinkImgBB}
            onChange={(e) =>
              this.setState(
                {
                  listLinkImgBB: e.target.value,
                },
                () => {
                  localStorage.setItem(
                    "listLinkImgBB",
                    this.state.listLinkImgBB
                  );
                }
              )
            }
          />
        </Row>
        <Row>
          <Col span={8}>
            <Input
              addonBefore="Blogger cookie"
              onChange={(e) => {
                localStorage.setItem("bloggerCookie", e.target.value);
                this.setState({
                  bloggerCookie: e.target.value,
                });
              }}
              value={this.state.bloggerCookie}
              placeholder="Blogger Cookie"
            />
          </Col>
          <Col span={4}>
            <Input
              addonBefore="Albumid"
              onChange={(e) => {
                localStorage.setItem("bloggerAlbumid", e.target.value);
                this.setState({
                  bloggerAlbumid: e.target.value,
                });
              }}
              value={this.state.bloggerAlbumid}
              placeholder="Blogger AlbumID"
            />
          </Col>
          <Col span={4}>
            <Input
              addonBefore="Authuser"
              onChange={(e) => {
                localStorage.setItem("authUserBlogger", e.target.value);
                this.setState({
                  authUserBlogger: e.target.value,
                });
              }}
              value={this.state.authUserBlogger}
              placeholder="authUserBlogger"
            />
          </Col>
          <Col span={4}>
            <Input
              addonBefore="batchid"
              onChange={(e) => {
                localStorage.setItem("bloggerbatchid", e.target.value);
                this.setState({
                  bloggerbatchid: e.target.value,
                });
              }}
              value={this.state.bloggerbatchid}
              placeholder="Blogger batchid"
            />
          </Col>
          <Col span={4}>
            <Input
              addonBefore="album_abs_position"
              onChange={(e) => {
                localStorage.setItem(
                  "bloggeralbum_abs_position",
                  e.target.value
                );
                this.setState({
                  bloggeralbum_abs_position: e.target.value,
                });
              }}
              value={this.state.bloggeralbum_abs_position}
              placeholder="Blogger album_abs_position"
            />
          </Col>
        </Row>
        {/* <img src={this.state.test} /> */}{" "}
        <Button
          type="primary"
          htmlType="submit"
          onClick={this.imgBBFlow}
          loading={this.state.imageLoading}
        >
          Upload to Imgbb{" "}
        </Button>{" "}
        <Button
          type="primary"
          htmlType="submit"
          onClick={this.imgBBFlowViaAPI}
          loading={this.state.imageLoading}
        >
          Upload to Imgbb via API{" "}
        </Button>{" "}
        <Button
          type="primary"
          htmlType="submit"
          onClick={this.imgUrFlow}
          loading={this.state.imageLoading}
        >
          Upload to IMGUR{" "}
        </Button>{" "}
        <Button
          type="primary"
          htmlType="submit"
          onClick={this.bloggerFlow}
          loading={this.state.imageLoading}
        >
          Upload to Blogger{" "}
        </Button>{" "}
        <AddPost
          ref="addPost"
          listLinkImage={this.state.listLinkImgBB}
          megalink={this.state.megaLink}
          googlelink={this.state.googleLink}
          anon={this.state.annofileLink}
          bayfile={this.state.bayfileLink}
          racaty={this.state.racatyLink}
          megaServer={this.state.serverBackendMega}
          zipNamePre={this.state.zipNamePre}
        />
      </div>
    );
  }
}

export default MainForm;
