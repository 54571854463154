import axios from "axios";
import config from "./config";

function uploadFile(file, name, uploadProgress) {
  let formData = new FormData();
  formData.append("file", new File([file], file.name ? file.name : name));
  return axios
    .post(`${config().SERVER}/https://api.anonfile.com/upload`, formData, {
      onUploadProgress: pro => {
        uploadProgress(pro);
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log("Error", error);
      throw new Error(error);
    });
}

export default {
  uploadFile
};
