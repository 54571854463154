import axios from "axios";
import Cookies from "universal-cookie";
import config from "./config";
const COOKIES = new Cookies();
async function uploadFile(file, name, sess_id, uploadProgress) {
  var formData = new FormData();
  formData.append("sess_id", sess_id);
  formData.append("utype", "reg");
  formData.append("file_0", file, name);
  formData.append("keepalive", 1);
  COOKIES.set("xfss", sess_id, { path: "/" });

  return axios
    .post(
      `${
        config().SERVER
      }/https://srv12.racaty.com/cgi-bin/upload.cgi?upload_type=file&utype=reg`,
      formData,
      {
        withCredentials: true,
        onUploadProgress: uploadProgress
      }
    )
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log("Error", error);
      throw new Error(error);
    });
}

export default {
  uploadFile
};
